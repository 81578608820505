// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

//= require jquery
//= require popper
//= require rails-ujs

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false


import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

// ハンバーガーメニュー、ヘッダー固定
$(function() {
  var $header = $('.header');
  // Nav Toggle Button
  $('#nav-toggle').click(function(){
    $header.toggleClass('open');
    $('.header__nav-toggle').toggleClass('open');
    $('.header__global-nav').toggleClass('open');
  });

  $('#global-nav li a').on('click', function(){
      $('#nav-toggle').click();
  });

  $('#search-toggle').click(function(){
    $header.toggleClass('search-open');
    $('.header__search').toggleClass('search-open');
  });
});

